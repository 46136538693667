<template>
  <div class="content">
    <TitleInfo title1="数据监控" title2="报警记录"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div style="margin-left: 10px">设备名称：</div>
          <el-select v-model="queryForm.deviceID" placeholder="Select">
            <el-option
              v-for="item in deviceInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">报警时间：</div>
          <el-date-picker
            v-model="dateRang"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :shortcuts="shortcuts"
            format="YYYY-MM-DD HH:mm:ss"
            :clearable="false"
            style="width: 350px"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 10px"
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <!-- <el-button type="warning" @click="Export"
              ><i class="iconfont icon-daochu" /><span style="margin-left: 4px"
                >导出</span
              ></el-button
            > -->
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
          v-loading="loading"
          :element-loading-text="loadingText"
          element-loading-background="rgba(122, 122, 122, 0)"
        >
          <el-table-column prop="DeviceName" label="设备名称"></el-table-column>
          <el-table-column prop="ParameName" label="参数名称"></el-table-column>
          <el-table-column prop="DCSName" label="位号名称"></el-table-column>
          <el-table-column
            prop="Content"
            label="报警内容"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="报警时间"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="移除时间"
            width="200"
          ></el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetAlarmDataPageList } from '@/api/DataRecord'
import { GetDeviceFillList } from '@/api/Device'
import moment from 'moment'
const queryForm = ref({
  deviceID: '',
  dtStart: new Date(),
  dtEnd: new Date().getDate() - 3600 * 1000 * 24 * 7,
  pageIndex: 1,
  pageSize: 10
})
const deviceInfo = ref([])
const loading = ref(true)
const loadingText = ref('数据加载中...')
//起止日期
const shortcuts = ref([
  {
    text: '最近一周',
    value: () => {
      return [moment().subtract(7, 'days').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近一个月',
    value: () => {
      return [moment().subtract(1, 'months').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近三个月',
    value: () => {
      return [moment().subtract(3, 'months').calendar(), moment().endOf('day')]
    }
  }
])
const dateRang = ref([moment().startOf('day'), moment().endOf('day')])
const total = ref(0)
const tableData = reactive([])
onMounted(() => {
  initDeviceList()
})
const initDeviceList = () => {
  deviceInfo.value.length = 0
  deviceInfo.value.push({
    ID: '',
    Name: '全部'
  })
  GetDeviceFillList()
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            deviceInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          queryForm.value.deviceID = deviceInfo.value[0].ID
          initGetList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}

const initGetList = async () => {
  loadingText.value = '数据加载中...'
  loading.value = true
  total.value = 0
  tableData.length = 0
  //   console.log(moment(dateRang.value[0]).format('yyyy-MM-D HH:mm:ss'))
  //   console.log(moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss'))
  queryForm.value.dtStart = moment(dateRang.value[0]).format(
    'yyyy-MM-D HH:mm:ss'
  )
  queryForm.value.dtEnd = moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss')
  GetAlarmDataPageList(queryForm.value)
    .then((res) => {
      loading.value = false
      if (res) {
        total.value = res.Total
        tableData.length = 0
        if (res.Data.length > 0) {
          res.Data.forEach((element) => {
            tableData.push(JSON.parse(element))
          })
        }
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      loading.value = false
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const resetInfo = () => {
  dateRang.value = [moment().startOf('day'), moment().endOf('day')]
  queryForm.value = {
    deviceID: deviceInfo.value[0].ID,
    dtStart: moment(dateRang.value[0]).format('yyyy-MM-D HH:mm:ss'),
    dtEnd: moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss'),
    pageIndex: 1,
    pageSize: 10
  }

  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
